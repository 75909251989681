import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`10:00 EMOM of:`}</p>
    <p>{`1-Clean Deadlift to below knee, :02 pause`}</p>
    <p>{`1-Clean Deadlift from below knee to full extension`}</p>
    <p>{`1-Clean Deadlift`}</p>
    <p>{`*`}{`using 90% of Clean 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`50-Situps`}</p>
    <p>{`25-Deadlifts (185/125)`}</p>
    <p>{`40, 40, 20`}</p>
    <p>{`30, 30, 15`}</p>
    <p>{`20, 20, 10`}</p>
    <p>{`10, 10, 5`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Open WOD 19.2 will be announced tonight (Thursday) at
8:00pm at Games.CrossFit.com!  Although Friday is a rest day 19.2 will
be tomorrow’s class wod and we’ll throw down Friday night from
4:30-6:30.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Pop Up Mobility classes with Glenn will start next Wednesday,
March 6th, at 11:30am and Sunday, March 10th, at 12:00am.  This is free
for all Unlimited members, \\$10 for anyone else, so pop in if you can
make it!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      